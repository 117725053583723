<template>
  <div>
    <div class="win-box"></div>
    <div class="button-box flex align-items jc-center">
      <img @click="close" class="confirm-icon" src="@/assets/win/confirm.png" />
      <img @click="close" class="again-icon" src="@/assets/win/again.png" />
    </div>
    <img @click="close" class="close-icon" src="@/assets/win/close.png" />
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      
    }
  },
  computed:{
    
  },
  created(){
    
  },
  mounted(){

  },
  methods:{
    //关闭弹窗
    close(){
      this.$emit("close")
    }
  }
}
</script>

<style scoped>
  .win-box{
    width: 7.43rem;
    height: 7.93rem;
    background-image: url("~@/assets/win/pityTip.png");
    background-size: contain;
  }
  .close-icon{
    margin: 0.46rem auto 0;
    width: 0.65rem;
    height: 0.65rem;
  }
  
  .button-box{
    margin-top: -0.5rem;
  }
  .confirm-icon{
    width: 2.21rem;
    height: 0.78rem;
    margin-right: 0.22rem;
  }
  .again-icon{
    width: 2.51rem;
    height: 0.88rem;
  }
</style>
