<template>
  <div>
    <img class="page-bg" src="@/assets/lottery/bg.jpg" />
    <img class="coloured-ribbon" src="@/assets/lottery/coloured-ribbon.png" />
    <div class="page-container">
      <div class="title-box">
        <img class="title-icon" src="@/assets/title.png" />
      </div>
      <div class="roll-box" ref="rollBox">
        <div ref="rollText1">
          <p class="roll-text" v-for="item in luckList" :key="item.id">{{item["nickname"]+"获得了"+item["goodsName"]}}</p>
        </div>
        <div ref="rollText2">
          <p class="roll-text" v-for="item in luckList" :key="item.id">{{item["nickname"]+"获得了"+item["goodsName"]}}</p>
        </div>
      </div>
      <div class="prize-baseplate">
        <div class="prize-list flex align-items jc-center">
          <img @click="cutSwiper('prev')" class="arrow-icon" src="@/assets/lottery/arrow.png" />
          <div class="prize-frame flex align-items" ref="prizeFrame">
            <div class="flex align-items" ref="prizeBox" style="padding-right:0.15rem;">
              <img v-for="item in goodsList" :key="item.id" class="prize-icon" ref="prizeIcon" :src="item.picUrl" />
            </div>
            <div class="flex align-items">
              <img v-for="item in goodsList" :key="item.id" class="prize-icon" ref="prizeIcon" :src="item.picUrl" />
            </div>
          </div>
          <img @click="cutSwiper('next')" class="arrow-icon" style="transform: rotate(180deg)" src="@/assets/lottery/arrow.png" />
        </div>
        <div class="prize-item">
          <img v-for="(item,index) in 6" @click="getLuck(item)" :key="index" :class="['checked-prize-icon',checkedBox==item?'checked-prize-animation':'']" :src="require(`@/assets/lottery/${checkedBox==item?'checked-prize':'prize'}.png`)" />
        </div>
        <div class="button-box">
          <p class="lottery-frequency">点击盒子抽奖，剩余次数 {{availableCount}} 次</p>
          <img @click="skipPage('record')" class="button-icon" src="@/assets/lottery/record.png" />
          <img @click="pageBack" class="button-icon" style="margin-top:0.2rem;" src="@/assets/record/back.png" />
        </div>
      </div>
    </div>
    <Logo></Logo>
    <Popup ref="popup" v-slot="{ close }">
      <component @close="close" :is="popupName" :detail="myLuckDetail"></component>
    </Popup>
  </div>
</template>

<script type="text/javascript">
//组件
import Logo from "@/components/Logo.vue"
import Popup from "@/components/Popup.vue"
import DeficiencyTip from "@/views/components/DeficiencyTip.vue"
import EntityTip from "@/views/Lottery/components/EntityTip.vue"
import IntegralTip from "@/views/Lottery/components/IntegralTip.vue"
import PityTip from "@/views/Lottery/components/PityTip.vue"
import TicketTip from "@/views/Lottery/components/TicketTip.vue"
import lackTip from "@/views/Lottery/components/lackTip.vue"

//api
import { luckList,goodsList,userInfo,luck } from "@/api";

//store
import {mapState} from 'vuex'

//全局变量
let timer = null
let timer2 = null
let timer3 = null

export default {
  name: "Lottery",
  data() {
    return {
      popupName:"",//要显示的弹窗的名称
      checkedBox:0,//选中的盒子
      availableCount:0,//可用抽奖次数
      goodsList:[],//奖品列表
      luckList:[],//动态列表
      luckState:false,//是否正在抽奖
    }
  },
  computed:{
    ...mapState(['userInfo']),
  },
  components: {Logo,Popup,DeficiencyTip,EntityTip,IntegralTip,PityTip,TicketTip,lackTip},
  created(){
    this.getLuckList()
    this.getGoodsList()
    this.getUserInfo()
  },
  mounted(){
    
  },
  destroyed(){
    clearInterval(timer)
    clearInterval(timer2)
  },
  methods:{
    getLuckList(){//获取抽奖动态
      luckList().then(res=>{
        this.luckList = res
        let { rollBox,rollText1,prizeFrame,prizeBox } = this.$refs
        this.rollVertical(80,rollBox,rollText1)
        this.rollHorizontal(80,prizeFrame,prizeBox)
      })
    },
    getGoodsList(){//获取奖品列表
      goodsList().then(res=>{
        this.goodsList = res
      })
    },
    getUserInfo(){//获取可抽奖次数
      let {token,uid,nickname} = this.userInfo
      let params = {token,uid,nickname}
      userInfo(params).then(({availableCount})=>{
        this.availableCount = availableCount
      })
    },
    async getLuck(num){//抽奖
      if(!this.availableCount){
        this.showPopup("lackTip");
        return
      }
      if(this.luckState)return
      this.luckState = true
      // await this.getLuckAnimation()
      this.checkedBox = num;
      let {token,uid,nickname} = this.userInfo
      let params = {token,uid,nickname}
        // alert(JSON.stringify(params))
      luck(params).then(res=>{
        // alert(JSON.stringify(res))
        setTimeout(()=>{
          this.checkedBox=0;
          // await this.getLuckAnimation(false)
          this.luckState = false
          if(res.code==200){//中奖
            const {type} = res.data
            if(type==1){//实物
              this.showPopup("EntityTip")
            }else if(type==2){//卡券
              this.showPopup("TicketTip")
            }else if(type==3){//积分
              this.showPopup("IntegralTip")
            }
            this.myLuckDetail = res.data
            this.availableCount--
          }else if(res.code==405){//可用积分不足
            this.showPopup("DeficiencyTip")
          }else if(res.code==406){//未中奖
            this.showPopup("PityTip")
            this.availableCount--
          }else if(res.code==407){//抽奖次数已达上限
            this.$layer.msg("抽奖次数已达上限")
          }
        },800)
      })
    },
    showPopup(name){//显示弹窗
      this.popupName = name
      this.$refs.popup.open()
    },
    skipPage(page){//跳转页面
      this.$router.push(page)
    },
    cutSwiper(direction){
      let { prizeFrame,prizeBox,prizeIcon } = this.$refs
      let prizeIconDom = prizeIcon[0]
      let domGap = prizeIcon[1].offsetLeft-prizeIconDom.offsetLeft
      clearInterval(timer3)
      if(direction=="prev"){
        if(prizeFrame.scrollLeft-domGap<=0){
          prizeFrame.scrollLeft = prizeBox.scrollWidth
        }
        prizeFrame.scrollLeft-=domGap
      }else{
        prizeFrame.scrollLeft+=domGap
      }
      this.rollHorizontal(80,prizeFrame,prizeBox)
    },
    getLuckAnimation(bool=true,t=200){//抽奖动画
     return new Promise((success,fail)=>{
        if(bool){
          success()
          let { checkedBox } = this
          timer2 = setInterval(()=>{
            if(checkedBox+1>=4){if(checkedBox+1==4){checkedBox=6}else{if(checkedBox-1<=3){checkedBox=1}else{ checkedBox--}}}else{checkedBox++}
            this.checkedBox = checkedBox
          }, t);
        }else{
          setTimeout(()=>{clearInterval(timer2);setTimeout(()=>{success()},500)},2000)
        }
     })
    },
    rollVertical(t,box,dom) {
      box.scrollTop = 0;
      let rollStart = ()=>{
        if(box.scrollTop>=dom.scrollHeight){
          box.scrollTop = 0;
        }else{
          box.scrollTop++;
        }
      }
      timer = setInterval(rollStart, t);
    },
    rollHorizontal(t,box,dom) {
      // box.scrollLeft = 0;
      let rollStart = ()=>{
        if(box.scrollLeft>=dom.scrollWidth){
          box.scrollLeft = 0;
        }else{
          box.scrollLeft++;
        }
      }
      timer3 = setInterval(rollStart, t);
    },
    pageBack(){//页面返回
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>
.page-container,
.coloured-ribbon,
.page-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7.5rem;
  height: 15rem;
}
.coloured-ribbon {
  top: 3.16rem;
  left: 0.83rem;
  transform: none;
  width: 6.08rem;
  height: 8.71rem;
}
.page-container {
  height: 100%;
}
.title-box {
  padding-top: 1.18rem;
  width: 5.02rem;
  height: 1.83rem;
  margin: 0 auto;
}
.title-icon {
  width: 100%;
  height: 100%;
}
.roll-box {
  margin: 0.3rem 0 0.4rem;
  height: 0.31rem;
  overflow: hidden;
}
.roll-text {
  text-align: center;
  font-size: 0.31rem;
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  text-decoration: underline;
  color: #7596be;
  line-height: 0.4rem;
}
.prize-list {
  height: 1.91rem;
}
.arrow-icon {
  width: 0.4rem;
  height: 0.66rem;
}
.prize-frame{
  width: 5rem;
  height: 100%;
  margin: 0 0.3rem;
  overflow: hidden;
}
/* .prize-box {
} */
.prize-icon {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #ffffff;
  border-radius: 0.1rem;
  margin-left: 0.15rem;
  object-fit: cover;
}
.prize-icon:first-of-type {
  margin-left: 0;
}
.prize-item {
  display: grid;
  grid-template-columns: repeat(3, 1.9rem);
  grid-gap: 0.36rem 0.1rem;
  justify-content: center;
  margin: 0.58rem 0 0.4rem;
}
.checked-prize-icon {
  width: 1.9rem;
  height: 1.69rem;
}
.checked-prize-animation {
  width: 1.9rem;
  height: 1.69rem;
  animation:prize-shake 0.8s 1 ease-in;
  animation-iteration-count: infinite;
}
@keyframes prize-shake {
  0% {transform: scale(1);}
  10%, 20% {transform: scale(0.9) rotate(-3deg);}
  30%, 50%, 70%, 90% {transform: scale(1.1) rotate(3deg);}
  40%, 60%, 80% {transform: scale(1.1) rotate(-3deg);}
  100% {transform: scale(1) rotate(0);}
}
.prize-baseplate {
  width: 6.98rem;
  height: 7.37rem;
  background-image: url(../../assets/lottery/prize-baseplate.png);
  background-size: contain;
  margin: 0 auto;
}
.button-icon {
  width: 3.75rem;
  height: 0.96rem;
  margin: 0.7rem auto 0;
}
.lottery-frequency{
  text-align: center;
  font-size: 0.26rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.26rem;
  -webkit-text-stroke: 0.005rem #A0CEFA;
  text-stroke: 0.005rem #A0CEFA;
}
</style>
